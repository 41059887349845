/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.15 Gavel.glb 
*/

import React, { useRef } from 'react'
import { useGLTF, useTexture} from '@react-three/drei'

export default function Model(props) {
  const { nodes, materials } = useGLTF('/Gavel.glb')
  const texture = useTexture("/wood1.jpg")
  return (
    <group {...props} dispose={null} rotation={[0, Math.PI / 2, 0]}>
      <mesh geometry={nodes.Circle.geometry} rotation={[Math.PI / 2, 0, 0]} position={[0, -0.2, 0]}>
      <meshStandardMaterial 
      attach = "material"
      metalness={0.2}
      map = {texture}
      />
      </mesh>
      <mesh geometry={nodes.Circle001.geometry} rotation={[Math.PI / 2, 0, 0]} >
      <meshStandardMaterial 
      attach = "material"
      metalness={0.2}
      map = {texture}
      />
      </mesh>
      <mesh geometry={nodes.Cylinder_Cylinder001.geometry}  rotation={[Math.PI / 2, 0, 0]} >
      <meshStandardMaterial 
      attach = "material"
      metalness={0.2}
      map = {texture}
      />
      </mesh>
    </group>
  )
}

useGLTF.preload('/Gavel.glb')
