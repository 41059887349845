/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

//Source: https://www.cgtrader.com/free-3d-print-models/games-toys/game-accessories/super-mario-mystery-block

import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'

export default function Model(props) {
  const group = useRef()
  const { nodes, materials } = useGLTF('/mystery.glb')
  return (
    <group ref={group} {...props} dispose={null}>
      <group position={[-6.48, 19.31, 4.42]} rotation={[-Math.PI / 2, 0, 0]} scale={[-0.03, -9.46, -0.03]}>
        <mesh geometry={nodes.Mesh.geometry} material={materials['09 - Default']} />
        <mesh geometry={nodes.Mesh_1.geometry} material={materials['03 - Default']} />
        <mesh geometry={nodes.Mesh_2.geometry} material={materials['02 - Default']} />
      </group>
    </group>
  )
}

useGLTF.preload('/mystery.glb')
