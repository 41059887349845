/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'

export default function Model(props) {
  const group = useRef()
  const { nodes, materials } = useGLTF('/brain.glb')
  return (
    <group ref={group} {...props} dispose={null}>
      <mesh //nodes
        geometry={nodes.Neuro_Cell_1.geometry}
        material={materials.cell}
        rotation={[Math.PI / 2, 0, 0]}
        scale={0.011}
        position = {[0,0,0.004]}
      />
      <mesh //brain
        geometry={nodes.Cube.geometry}
        material={materials.brain}
        position={[0, 0.01, -0.01]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={0.01}
      />
    </group>
  )
}

useGLTF.preload('/brain.glb')
