/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

//Source: https://www.turbosquid.com/3d-models/film-clapper-max-free/556661

import React, { useRef } from 'react'
import { useGLTF, useTexture } from '@react-three/drei'
export default function Model(props) {
  const group = useRef()
  const { nodes, materials } = useGLTF('/Clapper.glb')
  const texture = useTexture("/zebra.jpg")
  return (
    <group ref={group} {...props} dispose={null}>
      <mesh //top clapper
        geometry={nodes.CubeFBXASC046001.geometry}
        position={[0.96, 16.28, 9.5]}
        rotation={[-1.31, 0, 0]}
        scale={100}
      >

      <meshStandardMaterial 
      attach = "material"
      metalness={0.2}
      map = {texture}
      />
      </mesh>
      <mesh //screw
        geometry={nodes.Sphere04FBXASC046005.geometry}
        material={nodes.Sphere04FBXASC046005.material}
        position={[-0.99, 16.26, 9.49]}
        rotation={[-2.92, 0, Math.PI / 2]}
        scale={[20.87, 20.87, -20.87]}
      />
      <mesh
        geometry={nodes.Sphere04FBXASC046004.geometry}
        material={nodes.Sphere04FBXASC046004.material}
        position={[-0.99, 14.02, 9.49]}
        rotation={[2.4, 0, Math.PI / 2]}
        scale={[20.87, 20.87, -20.87]}
      />
      <mesh
        geometry={nodes.Sphere04FBXASC046003.geometry}
        material={nodes.Sphere04FBXASC046003.material}
        position={[-0.99, 14.02, 6.42]}
        rotation={[-2.34, 0, Math.PI / 2]}
        scale={[20.87, 20.87, -20.87]}
      />
      <mesh
        geometry={nodes.Sphere04FBXASC046002.geometry}
        material={nodes.Sphere04FBXASC046002.material}
        position={[0.99, 14.02, 6.42]}
        rotation={[0.8, 0, -Math.PI / 2]}
        scale={20.87}
      />
      <mesh
        geometry={nodes.Sphere04FBXASC046001.geometry}
        material={nodes.Sphere04FBXASC046001.material}
        position={[0.99, 14.02, 9.49]}
        rotation={[-0.74, 0, -Math.PI / 2]}
        scale={[20.87, 20.87, 20.87]}
      />
      <mesh
        geometry={nodes.Sphere04.geometry}
        material={nodes.Sphere04.material}
        position={[0.99, 16.26, 9.49]}
        rotation={[0.22, 0, -Math.PI / 2]}
        scale={[20.87, 20.87, 20.87]}
      />
      <mesh //back plate
        geometry={nodes.CubeFBXASC046004.geometry}
        material={nodes.CubeFBXASC046004.material}
        position={[-1.68, 15.44, 9.01]}
        rotation={[-Math.PI / 2, 0, 0]}
        scale={100}
      />
      <mesh //front plate
        geometry={nodes.CubeFBXASC046003.geometry}
        material={nodes.CubeFBXASC046003.material}
        position={[0, 15.44, 9.01]}
        rotation={[-Math.PI / 2, 0, 0]}
        scale={100}
      />
      <group //core plates
      position={[0, 13.71, 0]} rotation={[-Math.PI / 2, 0, 0]} scale={100}>
        <mesh geometry={nodes.CubeFBXASC046002_1.geometry} material={nodes.CubeFBXASC046002_1.material} />
        <mesh geometry={nodes.CubeFBXASC046002_2.geometry} material={materials.MaterialFBXASC046002} />
      </group>
      <mesh //bottom clapper
        geometry={nodes.Cube.geometry}
        position={[0, 14.25, 0]}
        rotation={[-Math.PI / 2, 0, 0]}
        scale={100}
      >
        
      <meshStandardMaterial 
      attach = "material"
      metalness={0.2}
      map = {texture}
      />
      </mesh>
      <mesh
        geometry={nodes.Text.geometry}
        material={nodes.Text.material}
        position={[33.01, 6.29, -0.48]}
        rotation={[0, Math.PI / 2, 0]}
        scale={[320.97, 402.22, 100]}
      />
    </group>
  )
}

useGLTF.preload('/clapper.glb')
